
.appHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1400px;
  margin-top: 3%;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  flex-wrap: wrap;
  justify-content: space-around;
  /*margin-top: 3%;*/
}

.subContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 100vh;
}

.myHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.myMobileHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.title {
  text-align: left;
  padding: 3%;
  margin: 1%;
  max-width: 50vh;
}

.mobileTitle {
  text-align: center;
  padding: 3%;
  margin: 1%;
  /*max-width: 100vh;*/
}



.Data {
  padding: 2%;
  min-width: 40vh;
  max-width: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1D1D1F;
  color: white;
}

.mobileData {
  padding: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1D1D1F;
  color: white;
}

.logos {
  display: flex;
  bottom: 0;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5%;
  max-height: 15vh;
}

.card1 {
  background: linear-gradient(90deg, rgba(0,126,254,1) 0%, rgba(0,96,192,1) 100%);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 40vh;
  margin: 2%;
  flex-wrap: nowrap;
}